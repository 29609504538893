import React, { useState } from "react";
import BTable from "react-bootstrap/Table";
import {batch, useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import ShipmentModel from "../../../models/shipmentModel";
import PagedModel from "../../../models/pagedModel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";


import ToLocalDateTime, {calculateDifferenceInHours} from "../../../actions/date";
import Authorized from "../../../components/Authorized";
import {
    Admin,
    Cashier,
    CustomerAdmin,
    CustomerService,
    CustomerServiceManager,
    OpsManager,
    OpsOfficer,
    OpsSupervisor,
    Sales,
} from "../../../models/jobRole";
import {cancelShipmentAsync, searchShipmentsAsync,} from "../../../actions/shipmentsActions";
import {GiFootsteps} from "react-icons/gi";
import {AiFillEdit, AiFillPrinter, AiFillProfile} from "react-icons/ai";
import AddressAMSPortal from "./addressAMSportal";
import Select from "react-select";
import LookupModel from "../../../models/lookupModel";
import { PrepareMetaDataAsync, SetAlert } from "../../../actions/common";
import { ChnageParcelatCourier } from "../../../api/data";

interface IProps {
    onSelectAll: () => any;
    onSelectId: (id: string, shipmentNumber?: string) => void;
    pagedShipments: PagedModel<ShipmentModel>;
    selectedIds: string[];
    setTrackShipmentId: (id: string) => any;
    printLabel: (id: string) => any;
    printPartnerLabel: (id: string) => any;
    resolveStatus: (s: string) => any;
    resolveCouriers: (s: string) => any;
    editShipment: (shipment: ShipmentModel) => any;
}

const Component = (props: IProps) => {
    const [selectedUser, setSelectedUser] = useState<LookupModel | undefined>();
    const meta = useSelector((state: AppStateModel) => state.Meta);

    const dispatch = useDispatch();
    React.useEffect(() => {
        batch(() => {
            dispatch(PrepareMetaDataAsync());
        })
    }, [dispatch]);
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.shipments
    );
    
    const jobRole = useSelector((state: AppStateModel) => state.Profile).jobRole;

    const countryCode = useSelector(
        (state: AppStateModel) => state.Profile.currentTenant?.countryCode
    );

    const changeParcelatCourierAsync = async (request: {
      reservationId: string;
      newUser: string;
    }) => {
      const res = await ChnageParcelatCourier(request);
      if (res.succeeded) {
        dispatch(
          SetAlert({
            visible: true,
            data: "Parcelat Courier have been updated successfully",
            title: "Success",
            kind: "success",
          })
        );
      } else {
        dispatch(
          SetAlert({
            visible: true,
            data: res.data,
            title: "Error",
            kind: "error",
          })
        );
      }
    };

    return (
        <BTable striped bordered hover size="sm">
            <thead>
            <tr>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}/>
                <th style={{border: 0}}>{content.total}</th>

                <th>
                    {props.pagedShipments != null ? props.pagedShipments?.total : 0}
                </th>
            </tr>
            <tr>
                <th>
                    <Form.Check
                        onChange={props.onSelectAll}
                        type="checkbox"
                        checked={
                            props.selectedIds.length ===
                            props.pagedShipments?.matches?.length
                        }
                    />
                </th>
                <th>{content.referencesHeader}</th>
                <th>{content.creationDateHeader}</th>
                <th>{"Schedule Pickup Date"}</th>
                <th>{"Pickup Date"}</th>

                <th>{content.fromHeader}</th>
                <th>{content.toHeader}</th>
                <th>{"AMS Address"}</th>

                <th>{content.customerHeader}</th>
                <th>{content.codHeader}</th>
                <th>{content.statusHeader}</th>
                <th>{"Status Reason"}</th>

                <th>{content.courierHeader}</th>
                <th>{"Parcelat User"}</th>
                
                <th>{"Failed delivery"}</th>

                <th>Delivery Date</th>
                <th>Status Duration</th>
                <th>Scheduled Delivery Date</th>
                <th>Location</th>
                <th>Partner</th>
                {/*<th>First Attempt For Assigned For Pickup</th>*/}

                <th>{content.actions}</th>
            </tr>
            </thead>

            <tbody>
            {props.pagedShipments?.matches?.map(
                (row: ShipmentModel): any => (
                    <tr key={row.id} style={{
                        backgroundColor: row.traces?.some(i => i.activity == "Canceled" && i.isAutomatic) ? "#ff000033" : undefined

                    }}>
                        <td>
                            <Form.Check
                                key={row.id}
                                checked={
                                    !!props.selectedIds?.find((i) => i === row.id)
                                }
                                type="checkbox"
                                onChange={() => props.onSelectId(row.id, row.number)}
                            />
                        </td>
                        <td>
                            <Col>
                                <Row>
                                    {content.model.number}: {row.number}
                                </Row>
                                <Row>
                                    {content.model.uniqueReference}: {row.uniqueReference}
                                </Row>
                            </Col>
                        </td>
                        <td>{ToLocalDateTime(row.createdOn, countryCode!)}</td>
                        <td>{ToLocalDateTime(row.pickupDate, countryCode!)}</td>
                        <td>{ToLocalDateTime(
                            row.traces?.findLast((t) => t.activity === "PickedUp" || t.activity === "PickupFailed")?.date,
                            countryCode!
                        )}</td>

                        <td style={{maxWidth: 200}}>
                            <Col>
                                <Row>{row.shipper.contact?.name}</Row>
                                <Row>
                                    {row.shipper.address?.city +
                                        ", " +
                                        row.shipper.address?.addressLine1 ?? ""}
                                </Row>
                                <Row>
                                    {row.shipper.address?.addressLine2 && true &&
                                    row.shipper.address?.addressLine2 != ""
                                        ? `${row.shipper.address?.addressLine2},${
                                            row.shipper.address?.addressLine3 ?? ""
                                        }`
                                        : `${row.shipper.address?.addressLine3 ?? ""}`}
                                </Row>
                            </Col>
                        </td>
                        <td style={{maxWidth: 200}}>
                            <Col>
                                <Row>
                                    {row.consignee.contact?.name +
                                        ", " +
                                        row.consignee.contact?.phone ?? ""}
                                </Row>
                                <Row>
                                    {row.consignee.address?.city +
                                        ", " +
                                        row.consignee.address?.addressLine1 ?? ""}
                                </Row>
                            </Col>
                        </td>
                        <td>
                            <AddressAMSPortal shipmentId={row.id} consignee={row.consignee}/>
                        </td>

                        <td>{row.customerName}</td>
                        <td>{row.commodity.cod}</td>
                        <td>{row.statusLabel}</td>
                        <td>{(row.statusReason && row.statusReason != "0") ? row.statusReason : ""}</td>

                        <td>{props.resolveCouriers(row.courierId)}</td>
                        
                        <td style={{ display: 'flex', alignItems: "center" }}>
                            {row.reservationId ? <Select
                                value={selectedUser}
                                key={`selectedShippingMethod${selectedUser ?? row?.id}`}
                                className="basic-multi-select"
                                escapeClearsValue={true}
                                options={meta?.parcelatUsers ?? []}
                                placeholder={content.model.shippingMethod}
                                onChange={(selectedOptions) => {
                                    changeParcelatCourierAsync({reservationId: row.reservationId ?? "", newUser: selectedOptions?.label ?? "" })
                                    setSelectedUser(selectedOptions ?? undefined);
                                }}
                            /> : "---"}
                        </td>
                        <td style={{maxWidth: 20}}>
                            {row.traces?.filter(
                                (x) => x.activity === "FailedDelivery"
                            )?.length ?? 'None'
                            }
                        </td>
                        <td>
                            {ToLocalDateTime(
                                row.traces?.find((t) => t.activity === "Delivered")?.date,
                                countryCode!
                            )}
                        </td>
                        <td>{calculateDifferenceInHours(new Date(ToLocalDateTime(row?.traces?.[0]?.date!, countryCode!))) + " Hours"}</td>
                        <td>{ToLocalDateTime(row?.scheduledDeliveryDate, countryCode!)}</td>
                        <td>{row.locationId ?? ''}</td>
                        <td align="center">{row.partners?.[0] ?? '---'}</td>
                        <td>
                            <Authorized
                                allowedRoles={[
                                    Admin.label,
                                    OpsManager.label,
                                    OpsSupervisor.label,
                                    OpsOfficer.label,
                                    CustomerService.label,
                                    Cashier.label,
                                    Sales.label,
                                    CustomerServiceManager.label,
                                    CustomerAdmin.label,
                                ]}
                            >
                                <Button
                                    onClick={() => props.setTrackShipmentId(row.id)}
                                    className="icon-button main-button"
                                >
                                    <GiFootsteps color={"white"} size={21}/>
                                </Button>
                            </Authorized>
                            <Authorized
                                allowedRoles={[
                                    Admin.label,
                                    OpsManager.label,
                                    OpsSupervisor.label,
                                    OpsOfficer.label,
                                    CustomerAdmin.label,
                                    CustomerServiceManager.label,
                                ]}
                            >
                                <Button
                                    onClick={() => props.printLabel(row.id)}
                                    variant="primary"
                                    className="icon-button"
                                >
                                    <AiFillPrinter color={"white"} size={21}/>
                                </Button>
                            </Authorized>
                            <Authorized
                                allowedRoles={[
                                    Admin.label,
                                    OpsManager.label,
                                    OpsSupervisor.label,
                                    OpsOfficer.label,
                                    CustomerAdmin.label,
                                    CustomerServiceManager.label,
                                ]}
                            >
                                <Button
                                    onClick={() => props.printPartnerLabel(row.id)}
                                    variant="primary"
                                    className="icon-button"
                                    disabled={!row.partners?.[0]}
                                >
                                    <AiFillProfile color={"white"} size={21}/>
                                </Button>
                            </Authorized>

                            <Authorized
                                allowedRoles={[
                                    Admin.label,
                                    OpsManager.label,
                                    OpsSupervisor.label,
                                    CustomerAdmin.label,
                                    CustomerServiceManager.label
                                ]}
                            >
                                {!(jobRole == CustomerAdmin.label && row.status != "1") && (
                                    <Button
                                        onClick={() => props.editShipment(row)}
                                        variant="primary"
                                        className="icon-button"
                                    >
                                        <AiFillEdit color={"white"} size={21}/>
                                    </Button>
                                )}
                            </Authorized>
                            <Authorized allowedRoles={[CustomerAdmin.label]}>
                                {(row.status == "5" || row.status == "2" || row.status == "1") && (
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                cancelShipmentAsync(row, () =>
                                                    dispatch(searchShipmentsAsync())
                                                )
                                            )
                                        }
                                        variant="danger"
                                        className="icon-button"
                                    >
                                        {"X"}
                                    </Button>
                                )}
                            </Authorized>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </BTable>
    );
};

export default React.memo(Component);

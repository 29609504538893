import React, {useCallback, useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useSelector} from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import IUpdateShipmentModel from "../../../models/updateShipmentModel";
import Authorized from "../../../components/Authorized";
import {Admin, CustomerAdmin, OpsManager} from "../../../models/jobRole";
import Select from "react-select";
import {ForceUpdateStatus} from "../../../api/data";

interface IProps {
    visible: boolean;
    onHide: () => any;
    onDone: (update: any) => any;
    title: string;
    submitButton: string;
    shipment: ShipmentModel;
}

const Component = (props: IProps) => {
    const content = useSelector(
      (state: AppStateModel) => state.AppContent.shipments.newShipment
    );
    const [status, setStatus] = useState<number>(Number(props.shipment.status))
    const shipmentStatuses = useSelector((state: AppStateModel) => state.Meta?.shipmentStatuses);

    const [shipmentEdit, setShipmentEdit] = React.useState<IUpdateShipmentModel>({
        id: props.shipment.id,
        consignee: {
            contact: props.shipment.consignee.contact,
            address: {
                addressLine1: props.shipment.consignee.address?.addressLine1,
                addressLine2: props.shipment.consignee.address?.addressLine2,
                addressLine3: props.shipment.consignee.address?.addressLine3
            }
        },
        description: props.shipment.commodity.description,
        cod: props.shipment.commodity.cod,
    });

    const onSubmit = () => {
        props.onDone(shipmentEdit);
    };

    const onUpdateStatus = async () => {
        const res = await ForceUpdateStatus(props.shipment.id, status)
        if (res.succeeded) {
            props.onHide()
        }
    }
    const resolveStatus = useCallback((status: any) => {
        return shipmentStatuses?.find((s) => s.value === status)?.label;
    }, [shipmentStatuses]);
    return (
      <Modal
        show={props.visible}
        onHide={() => props.onHide()}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
      >
          <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                  {props.title}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Row>
                  <Col>
                      <Form.Label>Shipment Number</Form.Label>
                      <TextInput disabled={true} value={props.shipment.number}/>
                  </Col>

                  <Col>
                      <Form.Label>{content.uniqueReferenceField.placeHolder}</Form.Label>
                      <TextInput disabled={true}
                                 value={props.shipment.uniqueReference}/>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Card>
                          <Card.Body>
                              <Card.Title>{content.consigneeSectionTitle}</Card.Title>
                              <Form.Label>{content.nameField.placeHolder}</Form.Label>
                              <TextInput
                                onChange={(val) =>
                                  setShipmentEdit({
                                      ...shipmentEdit,
                                      consignee: {
                                          ...shipmentEdit.consignee,
                                          contact: {
                                              ...shipmentEdit.consignee.contact,
                                              name: val,
                                          },
                                      },
                                  })
                                }
                                value={shipmentEdit.consignee.contact?.name!}
                              />
                              <Form.Label>{content.phoneField.placeHolder}</Form.Label>
                              <TextInput
                                onChange={(val) =>
                                  setShipmentEdit({
                                      ...shipmentEdit,
                                      consignee: {
                                          ...shipmentEdit.consignee,
                                          contact: {
                                              ...shipmentEdit.consignee.contact,
                                              phone: val,
                                          },
                                      },
                                  })
                                }
                                value={shipmentEdit.consignee.contact?.phone!}
                              />
                              <Form.Label>{content.secondPhoneField.placeHolder}</Form.Label>
                              <TextInput
                                onChange={(val) =>
                                  setShipmentEdit({
                                      ...shipmentEdit,
                                      consignee: {
                                          ...shipmentEdit.consignee,
                                          contact: {
                                              ...shipmentEdit.consignee.contact,
                                              secondPhone: val,
                                          },
                                      },
                                  })
                                }
                                value={shipmentEdit.consignee.contact?.secondPhone!}
                              />
                              <Form.Label>{content.emailField.placeHolder}</Form.Label>
                              <TextInput
                                onChange={(val) =>
                                  setShipmentEdit({
                                      ...shipmentEdit,
                                      consignee: {
                                          ...shipmentEdit.consignee,
                                          contact: {
                                              ...shipmentEdit.consignee.contact,
                                              email: val,
                                          },
                                      },
                                  })
                                }
                                value={shipmentEdit.consignee.contact?.email!}
                              />
                          </Card.Body>
                      </Card>
                  </Col>


                  <Col>
                      {
                        props.shipment.statusLabel === "Created" &&
                        <Authorized allowedRoles={[CustomerAdmin.label]}>
                          <Form.Label>COD</Form.Label>
                          <TextInput value={shipmentEdit.cod}
                                     placeholder={props.shipment.commodity.cod}
                                     onChange={(val) =>
                                       setShipmentEdit({
                                           ...shipmentEdit,
                                           cod: val
                                       })
                                     }
                          />
                        </Authorized>
                      }

                      <Form.Label>Description</Form.Label>
                      <TextInput value={shipmentEdit.description ?? ""}
                                 placeholder={props.shipment.commodity.description ?? ""}
                                 onChange={(val) =>
                                     setShipmentEdit({
                                         ...shipmentEdit,
                                         description: val
                                     })
                                 }
                      />
                      <Card>
                          <Card.Body>
                              <Card.Title>{content.consigneeAdressTitle}</Card.Title>
                              <Form.Label>{content.addressLine1Field.placeHolder}</Form.Label>
                              <TextInput
                                  onChange={(val) =>
                                      setShipmentEdit({
                                          ...shipmentEdit,
                                          consignee: {
                                              ...shipmentEdit.consignee,
                                              address: {
                                                  ...shipmentEdit.consignee.address,

                                                  addressLine1: val
                                              }
                                          }
                                      })
                                  }
                                  value={shipmentEdit.consignee.address?.addressLine1 ?? ""}
                              />
                              <Form.Label>{content.addressLine2Field.placeHolder}</Form.Label>
                              <TextInput
                                  onChange={(val) =>
                                      setShipmentEdit({
                                          ...shipmentEdit,
                                          consignee: {
                                              ...shipmentEdit.consignee,
                                              address: {
                                                  ...shipmentEdit.consignee.address,
                                                  addressLine2: val
                                              }
                                          }
                                      })
                                  }
                                  value={shipmentEdit.consignee.address?.addressLine2 ?? ""}
                              />
                              <Form.Label>{content.addressLine3Field.placeHolder}</Form.Label>
                              <TextInput
                                  onChange={(val) =>
                                      setShipmentEdit({
                                          ...shipmentEdit,
                                          consignee: {
                                              ...shipmentEdit.consignee,
                                              address: {
                                                  ...shipmentEdit.consignee.address,
                                                  addressLine3: val
                                              }
                                          }
                                      })
                                  }
                                  value={shipmentEdit.consignee.address?.addressLine3 ?? ""}
                              />
                          </Card.Body>
                      </Card>
                  </Col>


              </Form.Row>
              <Authorized allowedRoles={[
                  Admin.label,
                  OpsManager.label
              ]}>


                  <div className={"py-3 border mt-3 px-3"}>
                      <h5>Update status</h5>{resolveStatus(status)}
                      <div className={"w-25"}>
                          <Select
                            value={{
                                value: status,
                                label: resolveStatus(status)
                            }}
                            key={`selectedStatuses${status}`}
                            className="basic-multi-select"
                            escapeClearsValue={true}
                            options={shipmentStatuses as any ?? []}
                            placeholder={"Status"}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    // @ts-ignore
                                    setStatus(selectedOption.value)

                                }
                            }}
                          />
                          <Button className={"mt-3"} onClick={onUpdateStatus}>
                              {"Save"}
                          </Button>
                      </div>


                  </div>
              </Authorized>
          </Modal.Body>
          <Modal.Footer>
              <Button onClick={onSubmit}>{props.submitButton}</Button>
          </Modal.Footer>
      </Modal>
    );
};

export default Component;

import React from "react";
import {useSelector} from "react-redux";
import AppStateModel from '../../models/appStateModel';
import SelectInput from "./SelectInput";

interface IProps {
    value: string
    isInvalid?: boolean;
    invalidMessage?: string;
    placeholder?: string;
    onChange: (title: any) => any;
    disabled?: boolean;
}

const Component = (props: IProps) => {
    const cities = useSelector((state: AppStateModel) => state.Meta?.cities)

    return (
        <SelectInput
            key={`citykey${props.value}`}
            value={props.value ? (cities || []).find((o) => o.label === props.value || o.value === props.value)?.value : undefined}
            isInvalid={props.isInvalid}
            invalidMessage={props.invalidMessage}
            options={cities ?? []}
            onChange={(id: string) =>
                props.onChange(cities?.find((c) => c.value === id)?.label)
            }
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    );
};

export default React.memo( Component);

import React, {useCallback, useState} from "react";
import moment from "moment";
import {batch, useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Pager from "../components/Pager";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import {SearchShipment} from "../../api/data";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import Authorized from "../../components/Authorized";
import CitiesSelect from "../components/CitiesSelect";

import {
    AssignToDriverAsync, ForcePickUpShipmentAsync,
    PrintLabelAsync,
    searchPickupShipmentsAsync,
    setShipmentsCriteriaAsync,
    setShipmentsForPickupCriteriaAsync,
    UpdateShipmentAsync,
} from "../../actions/shipmentsActions";
import {DateRangePicker} from "react-dates";

import LookupModel from "../../models/lookupModel";
import {
    Accountant,
    Admin,
    Cashier,
    CustomerAdmin,
    CustomerService,
    CustomerServiceManager,
    OpsManager,
    OpsOfficer,
    OpsSupervisor,
    Sales,
} from "../../models/jobRole";
//@ts-ignore
import SuccessBeep from '../../res/beepsuccess.mp3';
//@ts-ignore
import FailBee from '../../res/beepfail.mp3';
import {BsSearch} from "react-icons/bs";
import {AiOutlineCloudDownload} from "react-icons/ai";
import SelectCourierModal from "../shipments/components/selectCourierModal";
import ScanToPrint from "../shipments/components/scanToPrint";
import ShipmentModel from "../../models/shipmentModel";
import ShipmentsTable from "../shipments/components/shipmentsTable";
import {PrepareMetaDataAsync} from "../../actions/common";
import UpdateShipmentModal from "../shipments/components/updateShipmentModal";
import TrackShipmentModal from "../shipments/components/trackShipmentModal";
import BulkScanToPrint from "../shipments/components/bulkScanToPrint";
import ForcePickupModal from "./forcePickupModal";

type modalNames =
  | "none"
  | "assignDriver"
  | "updateShipment"
  | "scanToPrint"
  | "bulkScanToPrint"
  | "forcePickup"

const PickupDispatchScreen = () => {
    const content = useSelector(
      (state: AppStateModel) => state.AppContent.shipments
    );
    const pagedShipments = useSelector(
      (state: AppStateModel) => state.PagedShipments
    );
    const searchShipmentsCriteria = useSelector(
      (state: AppStateModel) => state.SearchShipmentsCriteria
    );
    const cities = useSelector((state: AppStateModel) => state.Meta?.cities);

    const [modalOn, setModalOn] = useState<modalNames>("none");
    const [searchValue, setSearchValue] = useState("");
    const [consigneeNameValue, setConsigneeNameValue] = useState("");
    const [consigneeNumberValue, setConsigneeNumberValue] = useState("");
    const [shipperName, setShipperName] = useState('')
    const [trackShipmentId, setTrackShipmentId] = useState<string | null>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [selectedReason, setSelectedReason] = React.useState<LookupModel | undefined>();
    const [selectedStatuses, setSelectedStatuses] = React.useState<LookupModel[] | undefined>([]);

    const [selectedCustomer, setSelectedCustomer] = React.useState<LookupModel | undefined>();
    const [selectedStation, setSelectedStation] = React.useState<LookupModel | undefined>();
    const [selectedCourier, setSelectedCourier] = React.useState<LookupModel | undefined>();
    const [, setSelectedConsigneeCity] = React.useState<LookupModel | undefined>();
    const [
        selectedShipmentForEdit,
        setSelectedShipmentForEdit,
    ] = useState<ShipmentModel | null>(null);


    const meta = useSelector((state: AppStateModel) => state.Meta);
    const [dateRangeFocusedInput, setdateRangeFocusedInput] = useState<any>();
    const [ReturnDateFromFocusedInput, setReturnDateFromFocusedInput] = useState<any>();
    const [
        codDateRangeFocusedInput,
        setCodDateRangeFocusedInput,
    ] = useState<any>();
    const [ReceiveDateFromFocusedInput, setReceiveDateFromFocusedInput] =
      useState<any>();
    const [
        dateRangeDeliverFocusedInput,
        setdateRangeDeliverFocusedInput,
    ] = useState<any>();
    const dispatch = useDispatch();


    React.useEffect(() => {
        batch(() => {
            dispatch(PrepareMetaDataAsync());
            dispatch(searchPickupShipmentsAsync());
        })

    }, [dispatch,]);

    const exportToExcel = useCallback(async () => {
        let res = await SearchShipment({
            ...searchShipmentsCriteria,
            statuses: ['1', '2', '5', '10']
        }, true);
        res.succeeded && window.open(res.data.location, "_blank", "noreferrer");
    }, [searchShipmentsCriteria]);

    const selectId = useCallback((value: string) => {
        if (selectedIds.length === 0) {
            setSelectedIds([value]);
        } else {
            if (selectedIds.find((i) => i === value)) {
                let arr = [...selectedIds];
                let i = arr.indexOf(value);
                if (i >= 0) {
                    arr.splice(i, 1);
                }
                setSelectedIds(arr);
            } else {
                setSelectedIds([...selectedIds, value]);
            }
        }
    }, [selectedIds]);

    const selectAll = useCallback(() => {
        if (selectedIds.length === pagedShipments?.matches?.length) {
            setSelectedIds([]);
        } else {
            let newArr: string[] = [];
            pagedShipments.matches.forEach((s: any) => newArr.push(s.id));
            setSelectedIds(newArr);
        }
    }, [pagedShipments?.matches, selectedIds.length]);
    const resolveStatus = useCallback((status: any) => {
        return meta?.shipmentStatuses?.find((s) => s.value === status)?.label;
    }, [meta?.shipmentStatuses]);
    const resolveCourier = useCallback((id: string) => {
        const st = meta?.couriers?.find((s) => s.value === id);
        return st?.label;
    }, [meta?.couriers]);
    const selectedIdCheck = useCallback(() => {
        return selectedIds.length !== 0;
    }, [selectedIds]);
    const refreshAfterAction = useCallback(() => {
        dispatch(searchPickupShipmentsAsync());
        setSelectedIds([]);
        setModalOn("none");
    }, [dispatch]);

    const onClear = useCallback(() => {
        dispatch(
          setShipmentsForPickupCriteriaAsync({
              pageNumber: 1,
              pageSize: 20,
              search: "",
              stationId: 0,
              numberContains: "",
              referenceContains: "",
              consigneeNumber: "",
              consigneeName: "",
              toCity: "",
              cod: "",
              consigneeCity: undefined,
              courier: undefined,
              CustomerId: undefined,
              driver: undefined,
              statuses: undefined,
              codCollectionDateFrom: undefined,
              codCollectionDateTo: undefined,
              deliveredFrom: undefined,
              deliveredTo: undefined,
              createdTo: undefined,
              createdFrom: undefined,
              ReturnDateFrom: undefined,
              ReturnDateTo: undefined,
              ReceivedDateFrom: undefined,
              ReceivedDateTo: undefined,
              statusReason: "",
              isNotConfirmedCustomer: undefined,
              shipperName: ''
          })
        );
        setSelectedStation(undefined);
        setSelectedCustomer(undefined);
        setSelectedCourier(undefined);
        setSelectedConsigneeCity(undefined);
        setSelectedStatuses(undefined);
        setSelectedReason(undefined)
        setSearchValue("");
        setConsigneeNameValue("");
        setConsigneeNumberValue("");
        setSelectedIds([]);
        setSelectedShipmentForEdit(null);
        setdateRangeFocusedInput({});
        setShipperName('')
        // dispatch(searchShipmentsAsync())
    }, []);

    const handleKeyPress = (event: any, callback: () => any) => {
        if (event.key === "Enter") {
            callback();
        }
    };

    const renderModal = useCallback((val: modalNames) => {
        switch (val) {

            case "assignDriver":
                if (selectedIdCheck()) {
                    return (
                      <SelectCourierModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(courierId) => {
                            dispatch(
                              AssignToDriverAsync(selectedIds, courierId, () =>
                                refreshAfterAction()
                              )
                            );
                        }}
                        options={meta?.couriers}
                      />
                    );
                } else return <></>;
            case "updateShipment":
                return (
                  <UpdateShipmentModal
                    visible={true}
                    onHide={() => {
                        setSelectedShipmentForEdit(null);
                        setModalOn("none");
                    }}
                    title={content.updateShipment.title}
                    submitButton={content.updateShipment.submit}
                    shipment={selectedShipmentForEdit!}
                    onDone={(update: any) => {
                        dispatch(UpdateShipmentAsync(update, () => refreshAfterAction()));
                    }}
                  />
                );

            case "forcePickup":
                return (
                    <ForcePickupModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(ids: any) => {
                            dispatch(
                                ForcePickUpShipmentAsync(ids, () => refreshAfterAction())
                            );
                        }}
                        title={content.forcePickupButton}
                        submitButton={content.forcePickupButton}
                        allowedStatuses={["1", "2", "5"]}
                    />
                );

            case "scanToPrint":
                return <ScanToPrint visible={true}
                                    onDone={() => setModalOn("none")}/>;

            case "bulkScanToPrint":
                return <BulkScanToPrint visible={true}
                                        onDone={() => setModalOn("none")}/>;
            default:
                return <></>;
        }
    }, [selectedShipmentForEdit?.id, selectedIds]);

    const renderActionButton = (label: string, onPress: () => any) => {
        return (
          <Button aria-label={label} onClick={() => onPress()} variant="light">
              {label}
          </Button>
        );
    };
    const statuses = meta?.shipmentStatuses?.filter(status => ['1', '2', '5', '10'].includes(status.value))
    return (
      <div style={{ padding: "1vw" }}>
          {renderModal(modalOn)}

          {trackShipmentId !== null && (
            <TrackShipmentModal
              visible={true}
              onHide={() => setTrackShipmentId(null)}
              shipmentId={trackShipmentId ?? ""}
            />
          )}
          <Card>
              <Card.Header>
                  <Row>
                      <Col lg={2} className="px-md-4">
                          <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                          >
                              <Select
                                key={`selectedCustomer__${selectedCustomer}`}
                                value={selectedCustomer}
                                styles={{ control: customControlStyles }}
                                options={meta?.customers}
                                isClearable
                                placeholder={content.model.customerName}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                          "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }

                                    setSelectedCustomer({
                                        label: selectedOption?.label!,
                                        value: selectedOption?.value!,
                                    });

                                    dispatch(
                                      //@ts-ignore
                                      setShipmentsForPickupCriteriaAsync({
                                          //@ts-ignore
                                          CustomerId: selectedOption?.value,
                                      })
                                    );
                                }}
                              />
                          </Authorized>
                      </Col>
                      <Col lg={2} className="px-md-4">
                          <Select
                            key={`selectedReasonX_${selectedReason}`}
                            value={selectedReason}
                            className="basic-multi-select"
                            isClearable
                            options={meta?.statusReasons}
                            placeholder={"Reason"}
                            onChange={(selectedOption) => {
                                setSelectedReason(selectedOption ?? undefined)
                                dispatch(
                                  setShipmentsForPickupCriteriaAsync({
                                      statusReason: selectedOption?.value,
                                  })
                                )


                            }}
                          />
                      </Col>
                      <Col lg={2} className="px-md-4">
                          <Select
                              isMulti
                              value={selectedStatuses}
                              key={`selectedStatuses${selectedStatuses}`}
                              className="basic-multi-select"
                              escapeClearsValue={true}
                              options={statuses}
                              placeholder={content.model.status}
                              onChange={(selectedOptions) => {
                                  let statuses: string[] = [];
                                  let statusModel: LookupModel[] = [];
                                  if (Array.isArray(selectedOptions)) {
                                      selectedOptions?.forEach((element: any) => {
                                          statuses.push(element.value);
                                          statusModel.push({
                                              value: element.value,
                                              label: element.label,
                                          });
                                      });
                                  }
                                  setSelectedStatuses(statusModel);
                                  dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          statuses: statuses,
                                      })
                                  );

                              }}
                          />
                      </Col>

                      <Col lg={2} className="px-md-4">
                          <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                          >
                              <Select
                                key={`selectedCourier${selectedCourier}`}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                          "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }
                                    setSelectedCourier({
                                        value: selectedOption?.value!,
                                        label: selectedOption?.label!,
                                    });
                                    dispatch(
                                      //@ts-ignore
                                      setShipmentsForPickupCriteriaAsync({
                                          //@ts-ignore
                                          courier: selectedOption?.value,
                                      })
                                    );
                                }}
                                isClearable
                                value={selectedCourier}
                                options={meta?.couriers}
                                placeholder="Courier"
                              />
                          </Authorized>
                      </Col>

                      <Col lg={2} className="px-md-4">
                          <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                          >
                              <Select
                                key={`selectedStation__${selectedStation}`}
                                value={selectedStation}
                                styles={{ control: customControlStyles }}
                                options={meta?.stations}
                                isClearable
                                placeholder={content.model.stationId}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                          "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }

                                    setSelectedStation({
                                        label: selectedOption?.label!,
                                        value: selectedOption?.value!,
                                    });

                                    dispatch(
                                      //@ts-ignore
                                      setShipmentsForPickupCriteriaAsync({
                                          //@ts-ignore
                                          stationId: selectedOption?.value,
                                      })
                                    );
                                }}
                              />
                          </Authorized>
                      </Col>

                      <Col lg={2} className="px-md-4">
                          <CitiesSelect
                            value={String(searchShipmentsCriteria?.consigneeCity) ?? ""}
                            onChange={(val: any) => {
                                dispatch(
                                  setShipmentsForPickupCriteriaAsync({
                                      ...searchShipmentsCriteria,
                                      consigneeCity: cities?.find((c) => c.label == val)?.label,
                                  })
                                );
                                dispatch(searchPickupShipmentsAsync());
                            }}
                            placeholder={content.consigneeCity}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={2} className="px-md-4 mb-3">
                          <div style={{
                              height: 37,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "lightgrey",
                              borderRadius: "3px"
                          }}>
                              <DateRangePicker
                                noBorder={true}
                                isOutsideRange={() => false}
                                small
                                isDayBlocked={() => false}
                                minimumNights={0}
                                startDatePlaceholderText="Created From"
                                endDatePlaceholderText="Created To"
                                startDate={
                                    searchShipmentsCriteria.createdFrom
                                      ? moment(searchShipmentsCriteria.createdFrom)
                                      : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    searchShipmentsCriteria.createdTo
                                      ? moment(searchShipmentsCriteria.createdTo)
                                      : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => {
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          createdFrom: startDate?.toDate(),
                                          createdTo: endDate?.toDate(),
                                      })
                                    );
                                }}
                                focusedInput={dateRangeFocusedInput}
                                onFocusChange={(focusedInput) => {
                                    setdateRangeFocusedInput(focusedInput);
                                }}
                              />
                          </div>
                      </Col>
                      <Col lg={2} className="px-md-4 mb-3">
                          <div style={{
                              height: 37,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "lightgrey",
                              borderRadius: "3px"
                          }}>
                              <DateRangePicker
                                noBorder={true}
                                isOutsideRange={() => false}
                                small
                                minimumNights={0}
                                startDatePlaceholderText="Deliver From"
                                endDatePlaceholderText="Deliver To"
                                startDate={
                                    searchShipmentsCriteria.deliveredFrom
                                      ? moment(searchShipmentsCriteria.deliveredFrom)
                                      : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    searchShipmentsCriteria.deliveredTo
                                      ? moment(searchShipmentsCriteria.deliveredTo)
                                      : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => {
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          deliveredFrom: startDate?.toDate(),
                                          deliveredTo: endDate?.toDate(),
                                      })
                                    );
                                }}
                                focusedInput={dateRangeDeliverFocusedInput}
                                onFocusChange={(focusedInput) => {
                                    setdateRangeDeliverFocusedInput(focusedInput);
                                }}
                              />
                          </div>
                      </Col>
                      <Col lg={2} className="px-md-4 mb-3">
                          <div style={{
                              height: 37,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "lightgrey",
                              borderRadius: "3px"
                          }}>
                              <DateRangePicker
                                noBorder={true}
                                isOutsideRange={() => false}
                                small
                                minimumNights={0}
                                startDatePlaceholderText="Received from"
                                endDatePlaceholderText="Received To"
                                startDate={
                                    searchShipmentsCriteria.ReceivedDateFrom
                                      ? moment(searchShipmentsCriteria.ReceivedDateFrom)
                                      : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    searchShipmentsCriteria.ReceivedDateTo
                                      ? moment(searchShipmentsCriteria.ReceivedDateTo)
                                      : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => {
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          ReceivedDateFrom: startDate?.toDate(),
                                          ReceivedDateTo: endDate?.toDate(),
                                      })
                                    );
                                }}
                                focusedInput={ReceiveDateFromFocusedInput}
                                onFocusChange={(focusedInput) => {
                                    setReceiveDateFromFocusedInput(focusedInput);
                                }}
                              />
                          </div>
                      </Col>
                      <Col lg={2} className="px-md-4 mb-3">
                          <div style={{
                              height: 37,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "lightgrey",
                              borderRadius: "3px"
                          }}>
                              <DateRangePicker
                                noBorder={true}
                                isOutsideRange={() => false}
                                small
                                isDayBlocked={() => false}
                                minimumNights={0}
                                startDatePlaceholderText="Cod date from"
                                endDatePlaceholderText="Cod date to"
                                startDate={
                                    searchShipmentsCriteria.codCollectionDateFrom
                                      ? moment(searchShipmentsCriteria.codCollectionDateFrom)
                                      : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    searchShipmentsCriteria.codCollectionDateTo
                                      ? moment(searchShipmentsCriteria.codCollectionDateTo)
                                      : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => {
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          codCollectionDateFrom: startDate?.toDate(),
                                          codCollectionDateTo: endDate?.toDate(),
                                      })
                                    );
                                }}
                                focusedInput={codDateRangeFocusedInput}
                                onFocusChange={(focusedInput) => {
                                    setCodDateRangeFocusedInput(focusedInput);
                                }}
                              />
                          </div>
                      </Col>
                      <Col lg={2} className="px-md-4 mb-3">
                          <div style={{
                              height: 37,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "lightgrey",
                              borderRadius: "3px"
                          }}>
                              <DateRangePicker
                                noBorder={true}
                                isOutsideRange={() => false}
                                small
                                minimumNights={0}
                                startDatePlaceholderText="Return from"
                                endDatePlaceholderText="Return To"
                                startDate={
                                    searchShipmentsCriteria.ReturnDateFrom
                                      ? moment(searchShipmentsCriteria.ReturnDateFrom)
                                      : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    searchShipmentsCriteria.ReturnDateTo
                                      ? moment(searchShipmentsCriteria.ReturnDateTo)
                                      : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => {
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          ReturnDateFrom: startDate?.toDate(),
                                          ReturnDateTo: endDate?.toDate(),
                                      })
                                    );
                                }}
                                focusedInput={ReturnDateFromFocusedInput}
                                onFocusChange={(focusedInput) => {
                                    setReturnDateFromFocusedInput(focusedInput);
                                }}
                              />
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={2} className="px-md-4">
                          <InputGroup className="mb-3">
                              <Form.Control
                                style={{ height: 35 }}
                                // size="sm"
                                placeholder={content.search}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyPress={(e: any) =>
                                  handleKeyPress(e, () =>
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          search: searchValue,
                                      })
                                    )
                                  )
                                }
                              />
                              <InputGroup.Append style={{ zIndex: 0 }}>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        setShipmentsForPickupCriteriaAsync({
                                            search: searchValue,
                                        })
                                      )
                                    }
                                    className="icon-button"
                                  >
                                      <BsSearch name={"search"} color={"white"}
                                                size={21}/>
                                  </Button>
                              </InputGroup.Append>
                          </InputGroup>
                      </Col>
                      <Col lg={2} className="px-md-4">
                          <InputGroup className="mb-3">
                              <Form.Control
                                style={{ height: 35 }}
                                placeholder={content.consigneeNameSearch}
                                value={consigneeNameValue}
                                onChange={(e) => setConsigneeNameValue(e.target.value)}
                                onKeyPress={(e: any) =>
                                  handleKeyPress(e, () =>
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          consigneeName: consigneeNameValue,
                                      })
                                    )
                                  )
                                }
                              />
                              <InputGroup.Append style={{ zIndex: 0 }}>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        setShipmentsForPickupCriteriaAsync({
                                            consigneeName: consigneeNameValue,
                                        })
                                      )
                                    }
                                    className="icon-button"
                                  >
                                      <BsSearch name={"search"} color={"white"}
                                                size={21}/>
                                  </Button>
                              </InputGroup.Append>
                          </InputGroup>
                      </Col>
                      <Col lg={2} className="px-md-4">
                          <InputGroup className="mb-3">
                              <Form.Control
                                style={{ height: 35 }}
                                placeholder={'Shipper Name'}
                                value={shipperName}
                                onChange={(e) => setShipperName(e.target.value)}
                                onKeyPress={(e: any) =>
                                  handleKeyPress(e, () =>
                                    dispatch(
                                      setShipmentsCriteriaAsync({
                                          shipperName: shipperName,
                                      })
                                    )
                                  )
                                }
                              />
                              <InputGroup.Append style={{ zIndex: 0 }}>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        setShipmentsForPickupCriteriaAsync({
                                            shipperName: shipperName,
                                        })
                                      )
                                    }
                                    className="icon-button"
                                  >
                                      <BsSearch name={"search"} color={"white"}
                                                size={21}/>
                                  </Button>
                              </InputGroup.Append>
                          </InputGroup>
                      </Col>
                      <Col lg={2} className="px-md-4">
                          <InputGroup className="mb-3">
                              <Form.Control
                                style={{ height: 35 }}
                                placeholder={content.consigneeNumberSearch}
                                value={consigneeNumberValue}
                                onChange={(e) => setConsigneeNumberValue(e.target.value)}
                                onKeyPress={(e: any) =>
                                  handleKeyPress(e, () =>
                                    dispatch(
                                      setShipmentsForPickupCriteriaAsync({
                                          consigneeNumber: consigneeNumberValue,
                                      })
                                    )
                                  )
                                }
                              />
                              <InputGroup.Append style={{ zIndex: 0 }}>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        setShipmentsForPickupCriteriaAsync({
                                            consigneeNumber: consigneeNumberValue,
                                        })
                                      )
                                    }
                                    className="icon-button"
                                  >
                                      <BsSearch name={"search"} color={"white"}
                                                size={21}/>
                                  </Button>
                              </InputGroup.Append>
                          </InputGroup>
                      </Col>
                      <Col md={2}>
                          <InputGroup className="mb-3 px-4 border">
                              <Form.Check size={50} className="px-1"
                                          type="checkbox"
                                          checked={searchShipmentsCriteria.isNotConfirmedCustomer ?? false}
                                          onChange={(e) => dispatch(
                                            setShipmentsForPickupCriteriaAsync({
                                                isNotConfirmedCustomer: e.target.checked,
                                            })
                                          )}/>
                              <Form.Text>
                                  From unconfirmed companies
                              </Form.Text>
                          </InputGroup>
                      </Col>

                  </Row>
                  <Row>
                      <Col lg={1.5} className="px-md-4">
                          <Button
                            variant="danger"
                            onClick={() => {
                                onClear();
                            }}
                          >
                              Clear
                          </Button>
                      </Col>
                      <Col>
                          <div style={{
                              display: "flex",
                              justifyContent: "flex-end"
                          }}>
                              <ButtonGroup>
                                  {renderActionButton("Force Pickup", () =>
                                      setModalOn("forcePickup")
                                  )}
                                  {renderActionButton("Scan To Print", () =>
                                    setModalOn("scanToPrint")
                                  )}
                                  {renderActionButton("Bulk Scan To Print", () =>
                                    setModalOn("bulkScanToPrint")
                                  )}

                                  {selectedIds.length > 0 && (
                                    <Authorized
                                      allowedRoles={[
                                          Admin.label,
                                          OpsOfficer.label,
                                          OpsManager.label,
                                          OpsSupervisor.label,
                                      ]}
                                    >
                                        {renderActionButton(content.assignDriverButton, () =>
                                          setModalOn("assignDriver")
                                        )}
                                    </Authorized>
                                  )}

                                  <Authorized
                                    allowedRoles={[
                                        Admin.label,
                                        OpsManager.label,
                                        OpsSupervisor.label,
                                        OpsOfficer.label,
                                        CustomerService.label,
                                        Cashier.label,
                                        Sales.label,
                                        Accountant.label,
                                        CustomerAdmin.label,
                                        CustomerServiceManager.label,
                                    ]}
                                  >
                                      <Button
                                        aria-label={content.export}
                                        onClick={() => exportToExcel()}
                                        variant="light"
                                      >
                                          <AiOutlineCloudDownload
                                            color={"black"} size={21}/>
                                      </Button>
                                  </Authorized>

                              </ButtonGroup>
                          </div>
                      </Col>
                  </Row>
              </Card.Header>
              <Card.Body>
                  <Row>
                      <Form.Group as={Col}>
                          <Row/>
                      </Form.Group>
                  </Row>
                  <ShipmentsTable
                    onSelectAll={selectAll}
                    onSelectId={selectId}
                    pagedShipments={pagedShipments}
                    printLabel={(id: string) => {
                        dispatch(PrintLabelAsync(id));
                    }}
                    printPartnerLabel={(id: string) => dispatch(PrintLabelAsync(id, false, true))}
                    
                    resolveStatus={resolveStatus}
                    selectedIds={selectedIds}
                    setTrackShipmentId={setTrackShipmentId}
                    resolveCouriers={resolveCourier}
                    editShipment={(shipmentData: ShipmentModel) => {
                        setSelectedShipmentForEdit(shipmentData);
                        setModalOn("updateShipment");
                    }}
                  />

                  {pagedShipments && pagedShipments?.total > 0 && (
                    <div
                      style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                    >
                        <Pager
                          currentPageNumber={searchShipmentsCriteria.pageNumber!}
                          numberOfPages={Math.ceil(
                            pagedShipments.total! / searchShipmentsCriteria.pageSize!
                          )}
                          onChange={(page: number) =>
                            dispatch(
                              setShipmentsForPickupCriteriaAsync({
                                  ...searchShipmentsCriteria,
                                  pageNumber: page,
                              })
                            )
                          }
                        />
                    </div>
                  )}
              </Card.Body>
          </Card>
      </div>
    );
};

const customControlStyles = (base: any) => ({
    ...base,
    height: 21!,
});


export default React.memo(PickupDispatchScreen);
